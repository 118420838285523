import { Button, Modal, Text, Grid, InjectedModalProps, useToast, Input } from '@pancakeswap/uikit'
import Link from 'next/link'
import Image from 'next/image'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

export const ModelNotify = ({ onDismiss }: { onDismiss: () => void }) => {
  const { account } = useActiveWeb3React()
  var newLocal = []
  const handleCancel = () => {
    if (!localStorage.getItem('isVotedCore')) {
      newLocal.push(account)
      localStorage.setItem('isVotedCore', JSON.stringify(newLocal))
      onDismiss()
    } else {
      const isVotes = JSON.parse(localStorage.getItem('isVotedCore'))
      newLocal.push(...isVotes, account)
      localStorage.setItem('isVotedCore', JSON.stringify(newLocal))
      onDismiss()
    }
  }

  const handleOk = () => {
    if (!localStorage.getItem('isVotedCore')) {
      newLocal.push(account)
      localStorage.setItem('isVotedCore', JSON.stringify(newLocal))
      onDismiss()
    } else {
      const isVotes = JSON.parse(localStorage.getItem('isVotedCore'))
      newLocal.push(...isVotes, account)
      localStorage.setItem('isVotedCore', JSON.stringify(newLocal))
      onDismiss()
    }
  }
  return (
    <Modal
      title="IMPORTANT UPDATE – CLAIM REWARDS"
      onDismiss={handleCancel}
      style={{ padding: '10px', overflow: 'auto' }}
    >
      <div style={{ display: 'flex', marginTop: '50px', justifyContent: 'center' }}>
        <Image src="/images/core/Logo.png" alt="" width="100px" height="40px" />
      </div>
      <Grid alignItems="center" justifyContent="center" mt="20px">
        <h1 style={{ color: 'white', fontWeight: 600, fontSize: '20px', textAlign: 'center' }}>
          Dear Core Mining Community,
        </h1>
        <br />
        <h1 style={{ color: 'white', fontWeight: 600, fontSize: '16px', textAlign: 'center' }}>
          During the development and operation of Core mining, our development team has conducted system checks and
          upgrades to optimize security and ensure the safety of users assets. The upgrade helps prevent risks from
          hackers, protecting personal information and assets across the entire network. <br />
          <br />
          As a result, the Validators system will temporarily suspend daily profit distribution during the update
          period. We sincerely appreciate the patience and support of the community while we complete this upgrade.{' '}
          <br />
          <br />
          Important Notes:
          <br />
          - All mining profits accumulated during the update period will remain fully preserved.
          <br />
          - Once the update is completed, users will be able to claim the total rewards accumulated throughout the
          upgrade process.
          <br />
          Thank you for your support and trust!
          <br />
          Core Development Team
          <br />
        </h1>
        <br />
      </Grid>
    </Modal>
  )
}
